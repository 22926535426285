import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import LoginPage from "./scenes/LoginPage/LoginPage";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";

function App() 
{
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = (email, password) => {
    // Check if the provided email and password match the expected values
    if (email === "efe@lexpai.com" && password === "0") 
    {
      setIsAuthenticated(true);
      console.log("Logged in!");
    } 
    else if (email === "ahmet@genemamakine.com" && password === "biofet")
    {
      setIsAuthenticated(true);
      console.log("Logged in!");
    }
    else 
    {
      // Handle incorrect login credentials here, such as showing an error message
      console.log("Invalid email or password");
    }
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
        {isAuthenticated && (
              <>
                <Sidebar isSidebar={isSidebar} />
                {/* <Topbar setIsSidebar={setIsSidebar} /> */}
              </>
            )}
            <div className="content">
            {isAuthenticated && (
              <>
                {/* <Sidebar isSidebar={isSidebar} /> */}
                <Topbar setIsSidebar={setIsSidebar} />
              </>
            )}
          <Routes>
            
            <Route
            path="/login"
            element={
              isAuthenticated ? <Navigate to="/" /> : <LoginPage onLogin={handleLogin} />
            }
          />
            <Route
              path="/*"
              element={
                isAuthenticated ? (
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/team" element={<Team />} />
                    <Route path="/contacts" element={<Contacts />} />
                    <Route path="/invoices" element={<Invoices />} />
                    <Route path="/form" element={<Form />} />
                    <Route path="/bar" element={<Bar />} />
                    <Route path="/pie" element={<Pie />} />
                    <Route path="/line" element={<Line />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/geography" element={<Geography />} />
                  </Routes>
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
          </Routes>
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
